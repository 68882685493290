/* eslint-disable no-unused-vars */
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import BtnBleuLeft from "Components/Boutons/BtnBleuLeft/btn-bleu-left";
import BtnEcouteMarche from "Components/Boutons/BtnEcouteMarche/BtnEcouteMarche";
import BtnRetour from "Components/Boutons/BtnRetour/btn-retour";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import {
  updateAuth,
  updateCompletionLevel,
  updateExperience,
} from "Redux/Actions/authActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { fetchGet, fetchPut, useFetchGetConditional } from "Services/api";
import data from "assets/countries";
import axios from "axios";
import { fr } from "date-fns/locale";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaPen, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import {
  addDayToDateAndCompare,
  changeOrderDate,
  formatDateWihoutDays,
} from "../../../Services/functions";
import "./ExperiencesPro.scss";
import { errorToast, successToast } from "Services/toastFunctions";

const ExperiencesPro = (props) => {
  const [currentUser, setCurrentUser] = useState({});

  // Liste des id à supprimer
  const [deleteIds, setDeleteIds] = useState([]);
  const { t } = useTranslation("common");

  const sectorData = useFetchGetConditional(
    "/sector_of_offers",
    props.auth.token,
    props.secondaryTables.sectorOfOffersData
  );
  const typeOfContractsData = useFetchGetConditional(
    "/type_of_contracts",
    props.auth.token,
    props.secondaryTables.typeOfContractsData
  );
  const domainData = useFetchGetConditional(
    "/domains",
    props.auth.token,
    props.secondaryTables.domainsData
  );

  useEffect(() => {
    if (currentUser?.resume?.experiences) {
      setExperiences(convertObjectNumericKeys());
    }
  }, [currentUser?.resume?.experiences]);

  useEffect(() => {
    if (props.auth.userConnected) setCurrentUser(props.auth.userConnected);
  }, [props.auth?.userConnected?.resume?.experiences]);

  useEffect(() => {
    sectorData.loaded &&
      typeOfContractsData.loaded &&
      domainData.loaded &&
      props.handleUpdateSecondaryTables({
        sectorOfOffersData: sectorData.data,
        typeOfContractsData: typeOfContractsData.data,
        domainsData: domainData.data,
      });
  }, [sectorData.loaded, typeOfContractsData.loaded, domainData.loaded]);

  const successUpdate = useRef(null);
  const [countForm, setCountForm] = useState(1);

  const [validated, setValidated] = useState(false);
  const [modified, setModified] = useState(false);

  const [errorMessageEndDate, setErrorMessageEndDate] = useState({
    message: "",
    index: null,
  });
  const [experiences, setExperiences] = useState([
    {
      jobname: "",
      company: "",
      typeOfContract: null,
      sector: null,
      domain: null,
      city: "",
      country: null,
      startDate: null,
      endDate: null,
      current: false,
    },
  ]);

  const convertObjectNumericKeys = (user = null) => {
    const currUserObject = user || currentUser;
    // If experiences is an object, convert it to an array
    if (typeof currUserObject.resume.experiences === "object")
      return Object.values(currUserObject.resume.experiences);
    return currUserObject.resume.experiences;
  };

  const verifyDataInstance = (i) => {
    if (
      (experiences[i].jobname != "" || experiences[i].jobname != null) &&
      (experiences[i].company != "" || experiences[i].company != null) &&
      (experiences[i].typeOfContract != "" ||
        experiences[i].typeOfContract != null) &&
      (experiences[i].city != "" || experiences[i].city != null) &&
      (experiences[i].country != "" || experiences[i].country != null) &&
      experiences[i].startDate != null &&
      (experiences[i].endDate != null || experiences[i].current)
    ) {
      return true;
    }
    return false;
  };

  const verifyData = () => {
    setValidated(true);
    var verifiedData = true;
    for (let index = 0; index < experiences.length; index++) {
      let check = verifyDataInstance(index);
      verifiedData = verifiedData ? check : false;
    }
    return verifiedData;
  };

  const checkExperiencesBeforeAdd = () => {
    let verifiedData = verifyData();
    addExperience(verifiedData);
  };

  const addExperience = (verifiedData) => {
    setValidated(true);
    if (verifiedData == true) {
      const newExperience = {
        jobname: "",
        company: "",
        typeOfContract: null,
        sector: null,
        domain: null,
        current: false,
        city: "",
        startDate: null,
        endDate: null,
        country: null,
      };
      setCountForm(countForm + 1);
      setExperiences([...experiences, newExperience]);
      setValidated(false);
    }
  };

  const majExperience = (e, i, champ) => {
    const newArray = [...experiences];
    if (champ == "jobname") {
      newArray[i].jobname = e.target.value;
    }
    if (champ == "company") {
      newArray[i].company = e.target.value;
    }
    if (champ == "sector") {
      newArray[i].sector = e.target.value;
    }
    if (champ == "domain") {
      newArray[i].domain = e.target.value;
    }
    if (champ == "typeOfContract") {
      newArray[i].typeOfContract = e.target.value;
    }
    if (champ == "current") {
      newArray[i].current = e.checked;
    }
    if (champ == "city") {
      newArray[i].city = e.target.value;
    }
    if (champ == "startDate") {
      newArray[i].startDate = e;
    }
    if (champ == "endDate") {
      newArray[i].endDate = e;
    }
    if (champ == "country") {
      newArray[i].country = e.target.value;
    }
    setExperiences(newArray);
    verifyData(i);
  };

  const supprExperience = (i, expId) => {
    const newArray = [...experiences];
    // Ajout de l'id à supprimer dans le tableau deleteIds
    if (expId) setDeleteIds([...deleteIds, expId]);
    newArray.splice(i, 1);
    setExperiences(newArray);
  };

  const Valider = async () => {
    setValidated(true);
    let verifiedData = verifyData();
    if (verifiedData) {
      setModified(false);
      await updateExperience();
    }
  };
  const updateExperience = async () => {
    let experiencesArray = JSON.parse(JSON.stringify(experiences));
    experiencesArray.forEach((experience) => {
      if (experience.startDate.length < 8)
        experience.startDate = changeOrderDate(experience.startDate);
      if (experience.endDate?.length < 8)
        experience.endDate = changeOrderDate(experience.endDate);

      if (experience.typeOfContract)
        experience.typeOfContract = `/api/type_of_contracts/${experience.typeOfContract.id}`;
      if (experience.domain)
        experience.domain = `/api/domains/${experience.domain.id}`;
      if (experience.sector)
        experience.sector = `/api/sector_of_offers/${experience.sector.id}`;
    });

    // On spécifie les expériences à supprimer
    if (deleteIds.length > 0)
      experiencesArray = [
        ...experiencesArray,
        ...deleteIds.map((id) => ({ id, deleted: true })),
      ];

    // On vide le tableau deleteIds
    setDeleteIds([]);

    const cVId = props.auth.userConnected.resume.id;
    const userId = props.auth.userConnected.id;

    const resume = {
      experiences: experiencesArray,
    };

    const updateResult = await fetchPut(`/cvs/${cVId}`, resume);
    if (updateResult.error) {
      errorToast(
        updateResult.error.response?.data?.detail || "Une erreur est survenue"
      );
      return;
    }

    const updatedUser = await fetchGet(
      `/users/${userId}/private`,
      props.auth.token
    );
    if (updatedUser.error) {
      errorToast(
        updatedUser.error.response?.data?.detail || "Une erreur est survenue"
      );
      return;
    }
    if (updatedUser.data?.currentUser)
      props.handleUpdateAuth({
        userConnected: updatedUser.data,
      });
    successToast("Votre mise à jour a bien été prise en compte");

    const completionRate = await fetchGet(
      `/users/${userId}/profile_completion_rate`
    );
    if (completionRate.error) {
      errorToast(
        completionRate.error.response?.data?.detail || "Une erreur est survenue"
      );
      return;
    }

    props.handleUpdateCompletionLevel(completionRate.data);
  };

  const findObject = (criteria, value) => {
    try {
      if (typeof value === "object" && value?.label) {
        switch (criteria) {
          case "sector":
            return sectorData.data.find(
              (sector) => sector.label === value.label
            );
          case "typeOfContract":
            return typeOfContractsData.data.find(
              (typeOfContract) => typeOfContract.label === value.label
            );
          case "domain":
            return domainData.data.find(
              (domain) => domain.label === value.label
            );
          default:
            return null;
        }
      }
      if (typeof value === "string") {
        switch (criteria) {
          case "sector":
            return sectorData.data.find(
              (sector) => sector.id === parseInt(value.split("/")[3])
            );
          case "typeOfContract":
            return typeOfContractsData.data.find(
              (typeOfContract) =>
                typeOfContract.id === parseInt(value.split("/")[3])
            );
          case "domain":
            return domainData.data.find(
              (domain) => domain.id === parseInt(value.split("/")[3])
            );
          default:
            return null;
        }
      }
    } catch {
      return null;
    }
  };

  /**
   * Fonction qui permet d'afficher les données d'un champ relationnel (ou string)
   *
   * @param {} data - data à afficher
   * @param {Object[]} reduxData - data redux (objet ou tableau)
   * @returns {String} - data à afficher
   */
  const displayRelationField = (data, reduxData) => {
    if (data?.label) return data.label;
    else if (typeof data === "string") {
      if (data?.includes("/api/")) {
        const uriID = parseInt(data.split("/")[3]);
        return reduxData?.find((item) => item.id === uriID)?.label;
      } else return data;
    } else return "ERREUR";
  };

  //? Fonction qui permet de comparer si la date de début est antérieure à la date de fin et gérer les messages d'erreurs pour l'utilisateur
  const handleDateChangeFin = (endDate, indexExperiences) => {
    let startDate = experiences[indexExperiences].startDate;
    if (startDate !== null) {
      if (addDayToDateAndCompare(startDate, endDate)) {
        setErrorMessageEndDate({
          message: "",
          index: null,
        });
        majExperience(
          new Intl.DateTimeFormat("fr-FR", {
            year: "numeric",
            month: "numeric",
          }).format(endDate, "MM/YYYY"),
          indexExperiences,
          "endDate"
        );
      } else {
        setErrorMessageEndDate({
          message: "La date de début doit être antérieure à la date de fin",
          index: indexExperiences,
        });
      }
    } else {
      setErrorMessageEndDate({
        message: "Veuillez remplir une date de début d'expérience",
        index: indexExperiences,
      });
    }
  };
  return (
    <div>
      <Toast ref={successUpdate} />
      {props.auth.isConnected && (
        <div className="article_actu">
          <NavBarTab
            id="desktop"
            select="profiledit"
            activeIndex={props.activeIndex}
            items={props.items}
          />
          <BtnEcouteMarche items={props.items} />

          <div className="titre_de_page">
            <h1 className="h1_trait_dessus">
              {t("profilEdition.professionalExperiences.header")}
            </h1>
            <BtnRetour />
          </div>
          <div className="formation_container">
            {/* <div className="flex justify-content-center"> */}
            <div className="formation_list">
              {modified && !experiences?.length && (
                <div className="counter">
                  <Checkbox
                    id="binary"
                    onChange={() =>
                      props.handleMenu({
                        activeItemProfilEdit: props.items.findIndex(
                          (v) => v.label == "Compétences"
                        ),
                      })
                    }
                    // checked={checked}
                  />
                  <label>
                    {t("profilEdition.professionalExperiences.noExperience")}
                  </label>
                </div>
              )}
              {domainData.loaded &&
              sectorData.loaded &&
              typeOfContractsData.loaded &&
              modified
                ? experiences?.map((item, indexExperiences) => {
                    return (
                      <div
                        className={"formation_form exp-pro_form"}
                        key={"modify xp " + indexExperiences}
                      >
                        <div className="formation_form_header">
                          <h1>
                            {t(
                              "profilEdition.professionalExperiences.experience"
                            )}{" "}
                            {indexExperiences + 1}
                          </h1>
                          <BtnBlanc
                            btnAction={() => {
                              supprExperience(indexExperiences, item.id);
                            }}
                          >
                            <FaTrash />
                          </BtnBlanc>
                        </div>
                        <div className="formation_form_columns">
                          <div className="field">
                            <h2>
                              {t("profilEdition.professionalExperiences.role")}{" "}
                              *
                            </h2>
                            <InputText
                              id="jobname"
                              placeholder={t(
                                "profilEdition.professionalExperiences.rolePlaceholder"
                              )}
                              required
                              value={item.jobname}
                              onChange={(e) =>
                                majExperience(e, indexExperiences, "jobname")
                              }
                            />
                            {item.jobname == "" && validated && (
                              <label className="validation">
                                {t(
                                  "profilEdition.professionalExperiences.roleValidation"
                                )}
                              </label>
                            )}
                          </div>
                          <div className="field">
                            <h2>
                              {t(
                                "profilEdition.professionalExperiences.company"
                              )}{" "}
                              *
                            </h2>
                            <InputText
                              id="company"
                              placeholder="Danone, BNP Paribas..."
                              label="Entreprise"
                              value={item.company}
                              onChange={(e) =>
                                majExperience(e, indexExperiences, "company")
                              }
                            />
                            {item.company == "" && validated && (
                              <label className="validation">
                                {t(
                                  "profilEdition.professionalExperiences.companyValidation"
                                )}
                              </label>
                            )}
                          </div>
                          <div className="field">
                            <h2>
                              {t(
                                "profilEdition.professionalExperiences.category"
                              )}{" "}
                              *
                            </h2>
                            <Dropdown
                              id="typeOfContract"
                              placeholder={t(
                                "profilEdition.professionalExperiences.categoryPlaceholder"
                              )}
                              value={findObject(
                                "typeOfContract",
                                item.typeOfContract
                              )}
                              options={typeOfContractsData.data}
                              onChange={(e) =>
                                majExperience(
                                  e,
                                  indexExperiences,
                                  "typeOfContract"
                                )
                              }
                            />
                            {item.typeOfContract == null && validated && (
                              <label className="validation">
                                {t(
                                  "profilEdition.professionalExperiences.categoryValidation"
                                )}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="formation_form_columns">
                          <div className="field">
                            <h2>
                              {t(
                                "profilEdition.professionalExperiences.sector"
                              )}
                            </h2>
                            <Dropdown
                              id="sector"
                              placeholder={t(
                                "profilEdition.professionalExperiences.sectorPlaceholder"
                              )}
                              label="Secteur"
                              filter
                              value={findObject("sector", item.sector)}
                              options={sectorData.data.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )}
                              onChange={(e) =>
                                majExperience(e, indexExperiences, "sector")
                              }
                            />
                          </div>
                          <div className="field">
                            <h2>
                              {t(
                                "profilEdition.professionalExperiences.domain"
                              )}
                            </h2>
                            <Dropdown
                              id="domain"
                              placeholder={t(
                                "profilEdition.professionalExperiences.domainPlaceholder"
                              )}
                              label="Domaine"
                              filter
                              value={findObject("domain", item.domain)}
                              options={domainData.data}
                              onChange={(e) =>
                                majExperience(e, indexExperiences, "domain")
                              }
                            />
                          </div>
                        </div>

                        <div className="formation_form_columns">
                          <div className="exp-pro_localisation">
                            <div className="label_date">
                              <h2>
                                {t(
                                  "profilEdition.professionalExperiences.city"
                                )}{" "}
                                *
                              </h2>
                              <InputText
                                id="city"
                                label="Ville"
                                placeholder="Paris, Metz..."
                                value={item.city}
                                onChange={(e) =>
                                  majExperience(e, indexExperiences, "city")
                                }
                              />
                              {item.city == "" && validated && (
                                <label className="validation">
                                  {t(
                                    "profilEdition.professionalExperiences.cityValidation"
                                  )}
                                </label>
                              )}
                            </div>
                            <div className="label_date">
                              <h2>
                                {t(
                                  "profilEdition.professionalExperiences.country"
                                )}{" "}
                                *
                              </h2>
                              <Dropdown
                                id="country"
                                label="Pays"
                                placeholder={t(
                                  "profilEdition.professionalExperiences.countryPlaceholder"
                                )}
                                value={item.country}
                                options={data}
                                editable
                                onChange={(e) =>
                                  majExperience(e, indexExperiences, "country")
                                }
                              />
                              {item.country == null && validated && (
                                <label className="validation">
                                  {t(
                                    "profilEdition.professionalExperiences.countryValidation"
                                  )}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="exp-pro_localisation">
                            <div className="label_date">
                              <h2>
                                {t(
                                  "profilEdition.professionalExperiences.startingDate"
                                )}{" "}
                                *
                              </h2>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={fr}
                              >
                                <DatePicker
                                  label="Date de début"
                                  placeholderText="Click to enter the start date"
                                  selected={
                                    item.startDate === " "
                                      ? null
                                      : item.startDate
                                  }
                                  onChange={(date) => (
                                    setErrorMessageEndDate({
                                      message: "",
                                      index: null,
                                    }),
                                    majExperience(
                                      new Intl.DateTimeFormat("fr-FR", {
                                        year: "numeric",
                                        month: "numeric",
                                      }).format(date, "MM/YYYY"),
                                      indexExperiences,
                                      "startDate"
                                    )
                                  )}
                                  labelFunc={() =>
                                    item.startDate !== "" &&
                                    item.startDate !== null
                                      ? formatDateWihoutDays(item.startDate)
                                      : "09/2019..."
                                  }
                                  views={["year", "month"]}
                                  cancelLabel="Annuler"
                                />
                              </MuiPickersUtilsProvider>
                              {item.startDate == null && validated && (
                                <label className="validation">
                                  {t(
                                    "profilEdition.professionalExperiences.startingDateValidation"
                                  )}
                                </label>
                              )}
                            </div>
                            <div
                              className={
                                !item.current
                                  ? "label_date"
                                  : "label_date hidden_date"
                              }
                            >
                              <h2>
                                {t(
                                  "profilEdition.professionalExperiences.endingDate"
                                )}{" "}
                                *
                              </h2>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={fr}
                              >
                                <DatePicker
                                  label="Date de fin"
                                  // placeholderText="Click to enter the start date"
                                  selected={
                                    item.endDate === " " ? null : item.endDate
                                  }
                                  onChange={(date) =>
                                    handleDateChangeFin(date, indexExperiences)
                                  }
                                  labelFunc={() =>
                                    item.endDate !== "" && item.endDate !== null
                                      ? formatDateWihoutDays(item.endDate)
                                      : "09/2019..."
                                  }
                                  views={["year", "month"]}
                                  cancelLabel="Annuler"
                                />
                              </MuiPickersUtilsProvider>
                              {item.endDate == null && validated && (
                                <label className="validation">
                                  La date de fin doit être renseignée
                                </label>
                              )}
                              {errorMessageEndDate &&
                                errorMessageEndDate.message !== "" &&
                                errorMessageEndDate.index ==
                                  indexExperiences && (
                                  <p className="errorMessageEndDate">
                                    {errorMessageEndDate.message}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="formation_form_columns">
                          <Checkbox
                            label="current"
                            key={item + indexExperiences}
                            // value={item.accept}
                            checked={item.current}
                            onChange={(e) =>
                              majExperience(e, indexExperiences, "current")
                            }
                          />
                          <label htmlFor="current">
                            {t(
                              "profilEdition.professionalExperiences.currentExperience"
                            )}
                          </label>
                        </div>
                      </div>
                    );
                  })
                : experiences?.at(0)?.jobname == ""
                ? t("profilEdition.professionalExperiences.none")
                : experiences
                    ?.sort((a, b) => {
                      // Tri des expériences professionnelles par :
                      // 1 - Expérience actuelle
                      // 2 - Date de fin
                      // 3 - Date de début
                      if (b?.current - a?.current !== 0) {
                        return b?.current - a?.current;
                      } else if (
                        new Date(b?.endDate || Date.now()) -
                          new Date(a?.endDate || Date.now()) !==
                        0
                      ) {
                        return (
                          new Date(b?.endDate || Date.now()) -
                          new Date(a?.endDate || Date.now())
                        );
                      } else {
                        return new Date(b?.startDate) - new Date(a?.startDate);
                      }
                    })
                    ?.map((item, indexXp) => {
                      if (item?.jobname)
                        return (
                          <div
                            className="formation_card exp-pro_card"
                            key={"display xp" + indexXp}
                          >
                            <p>
                              {formatDateWihoutDays(item.startDate)} -{" "}
                              {item.current
                                ? t(
                                    "profilEdition.professionalExperiences.today"
                                  )
                                : formatDateWihoutDays(item.endDate)}
                            </p>
                            <h4>
                              {item.company} - {item.city} ({item.country})
                            </h4>
                            {item.jobname && (
                              <div className="formation_card_item exp-pro_item">
                                <h5>
                                  <strong>
                                    {t(
                                      "profilEdition.professionalExperiences.role"
                                    )}
                                  </strong>{" "}
                                  :{" "}
                                </h5>
                                {item.jobname}
                              </div>
                            )}
                            {item.sector && (
                              <div className="formation_card_item exp-pro_item">
                                <h5>
                                  <strong>
                                    {t(
                                      "profilEdition.professionalExperiences.sector"
                                    )}
                                  </strong>{" "}
                                  :{" "}
                                </h5>
                                {displayRelationField(
                                  item.sector,
                                  sectorData?.data
                                )}
                              </div>
                            )}
                            {item.domain && (
                              <div className="formation_card_item exp-pro_item">
                                <h5>
                                  <strong>
                                    {t(
                                      "profilEdition.professionalExperiences.domain"
                                    )}
                                  </strong>{" "}
                                  :{" "}
                                </h5>
                                {displayRelationField(
                                  item.domain,
                                  domainData?.data
                                )}
                              </div>
                            )}
                            {item.typeOfContract && (
                              <div className="formation_card_item exp-pro_item">
                                <h5>
                                  <strong>
                                    {t(
                                      "profilEdition.professionalExperiences.category"
                                    )}
                                  </strong>{" "}
                                  :{" "}
                                </h5>
                                {displayRelationField(
                                  item.typeOfContract,
                                  typeOfContractsData?.data
                                )}
                              </div>
                            )}
                          </div>
                        );
                    })}

              <div
                className="formation_list_boutons"
                id="formation_list_boutons"
              >
                {modified ? (
                  <>
                    <div
                      className="formation_list_boutons-add"
                      onClick={() => checkExperiencesBeforeAdd()}
                    >
                      <i className="pi pi-plus-circle" size="2rem" />
                      <label>
                        {t(
                          "profilEdition.professionalExperiences.addExperience"
                        )}
                      </label>
                    </div>
                    <div>
                      <BtnBleuLeft
                        btnTexte={t("profilEdition.validate")}
                        btnAction={async () => await Valider()}
                      >
                        <FaCheck />
                      </BtnBleuLeft>
                    </div>
                  </>
                ) : (
                  <BtnBlancLeft
                    btnTexte={t("profilEdition.edit")}
                    btnAction={() => setModified(true)}
                  >
                    <FaPen />
                  </BtnBlancLeft>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ExperiencesPro.propTypes = {
  auth: PropTypes.object,
  secondaryTables: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  handleMenu: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
  handleUpdateExperience: PropTypes.func,
  handleUpdateCompletionLevel: PropTypes.func,
  handleUpdateSecondaryTables: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleUpdateExperience: (value) => {
    dispatch(updateExperience(value));
  },
  handleUpdateCompletionLevel: (value) => {
    dispatch(updateCompletionLevel(value));
  },
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExperiencesPro);
