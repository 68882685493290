import React from "react";
import "./Footer.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import { Link } from "react-router-dom";
import logo_paris1 from "assets/logo_paris1_blanc.webp";
import {
  FaFacebook,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaRegCopyright,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t } = useTranslation("common");

  return (
    <div className="footer">
      {props.auth.isConnected ? (
        <div className="footer__top">
          <div className="footer__top__li">
            <img
              src={logo_paris1}
              alt="Logo Panthéon Sorbonne"
              title="Logo Panthéon Sorbonne"
            />
            <div className="footer__top__li__socialMedia">
              <a
                href="https://www.facebook.com/UnivParis1PantheonSorbonne"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
              <a
                href="https://www.instagram.com/paris1pantheonsorbonne/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/school/sorbonneparis1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://twitter.com/SorbonneParis1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                href="https://www.youtube.com/user/UnivParis1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </a>
            </div>
          </div>
          <div className="footer__top__text">
            <div className="footer__column">
              <div className="footer__top__li">
                <h6 className="h1_trait_dessous">
                  <Link to={{ pathname: "/profil" }}>
                    {t("footerApresConnexion.title_profil")}
                  </Link>
                </h6>
                <br />
                <ul>
                  <li
                    onClick={() =>
                      props.handleMenu({
                        activeItemProfilEdit:
                          props.items.itemsProfilEdit.findIndex(
                            (v) => v.label == "Informations personnelles"
                          ),
                      })
                    }
                  >
                    <Link to={{ pathname: "/profil-edit" }}>
                      {t("footerApresConnexion.li_modifier_profil")}
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/profil" }}>
                      {t("footerApresConnexion.li_cv")}
                    </Link>
                  </li>
                  <li
                    onClick={() =>
                      props.handleMenu({
                        activeItemProfil: props.items.itemsProfil.findIndex(
                          (v) => v.label == "Offres sauvegardées"
                        ),
                      })
                    }
                  >
                    <Link to={{ pathname: "/profil" }}>
                      {t("footerApresConnexion.li_alerte")}
                    </Link>
                  </li>

                  <li
                    onClick={() =>
                      props.handleMenu({
                        activeItemProfilEdit:
                          props.items.itemsProfilEdit.findIndex(
                            (v) => v.label == "Paramètres de confidentialité"
                          ),
                      })
                    }
                  >
                    <Link to={{ pathname: "/profil-edit" }}>
                      {t("footerApresConnexion.li_confidentialite")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer__top__li">
                <h6 className="h1_trait_dessous">
                  <Link to={{ pathname: "/groupes" }}>
                    {t("footerApresConnexion.title_groupes")}
                  </Link>
                </h6>
                <br />
                <ul>
                  <li
                    onClick={() =>
                      props.handleMenu({
                        activeItemGroupes: props.items.itemsGroupes.findIndex(
                          (v) => v.label == "Mes groupes d'échanges"
                        ),
                      })
                    }
                  >
                    <Link to={{ pathname: "/groupes" }}>
                      {t("footerApresConnexion.li_groupes_echanges")}
                    </Link>
                  </li>
                  <li
                    onClick={() =>
                      props.handleMenu({
                        activeItemGroupes: props.items.itemsGroupes.findIndex(
                          (v) => v.label == "Événements de groupe"
                        ),
                      })
                    }
                  >
                    <Link to={{ pathname: "/groupes" }}>
                      {t("footerApresConnexion.li_groupes_evenements")}{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__top__li">
                <h6>
                  {t("footerApresConnexion.title_actus")}
                  <div className="trait_dessus"></div>
                </h6>
                <ul>
                  <li>
                    <Link to={{ pathname: "/actualites" }}>
                      {t("footerApresConnexion.li_actus_dernieres")}
                    </Link>
                  </li>
                  <li
                    onClick={() =>
                      props.handleMenu({
                        activeItemProfil: props.items.itemsProfil.findIndex(
                          (v) => v.label == "Conseils"
                        ),
                      })
                    }
                  >
                    <Link to={{ pathname: "/profil" }}>
                      {t("footerApresConnexion.li_actus_conseils")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer__top__li">
                <h6>
                  <Link to={{ pathname: "/offres" }}>
                    {t("footerApresConnexion.title_offres")}
                  </Link>
                  <div className="trait_dessus"></div>
                </h6>
                <ul>
                  <li
                    onClick={() =>
                      props.handleMenu({
                        activeItemOffre: props.items.itemsOffre.findIndex(
                          (v) => v.label == "Trouver une offre"
                        ),
                      })
                    }
                  >
                    <Link to={{ pathname: "/offres" }}>
                      {t("footerApresConnexion.li_offres_rechercher")}{" "}
                    </Link>
                  </li>
                  <li
                    onClick={() =>
                      props.handleMenu({
                        activeItemOffre: props.items.itemsOffre.findIndex(
                          (v) => v.label == "Déposer une offre"
                        ),
                      })
                    }
                  >
                    <Link to={{ pathname: "/offres" }}>
                      {t("footerApresConnexion.li_offres_deposer")}{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="footer__top">
          <div className="footer__top__li">
            <img
              className="logo_top"
              src={logo_paris1}
              onClick={() =>
                window.open("https://www.pantheonsorbonne.fr/", "_blank")
              }
              alt="Logo Panthéon Sorbonne"
              title="Logo Panthéon Sorbonne"
            />
            <div className="footer__top__li__socialMedia">
              <a
                href="https://www.facebook.com/UnivParis1PantheonSorbonne"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
              <a
                href="https://www.instagram.com/paris1pantheonsorbonne/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/school/sorbonneparis1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://twitter.com/SorbonneParis1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                href="https://www.youtube.com/user/UnivParis1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </a>
            </div>
          </div>
          <div className="footer__top__text">
            <div className="footer__column">
              <div className="footer__top__li">
                <h6 className="h1_trait_dessous footerTrait">
                  <Link to={{ pathname: "/" }}>
                    {t("footerHorsConnexion.title_event_actus_offres")}
                  </Link>
                </h6>
                <br />
                <ul>
                  <li>
                    <Link to={{ pathname: "/evenements" }}>
                      {t("footerHorsConnexion.li_event")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/actualites" }}>
                      {t("footerHorsConnexion.li_actus")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/offres" }}>
                      {t("footerHorsConnexion.li_offers")}{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer__top__li">
                <h6 className="h1_trait_dessous footerTrait">
                  <Link to={{ pathname: "/" }}>
                    {t("footerHorsConnexion.title_mecenat")}
                  </Link>
                </h6>
                <br />
                <ul>
                  <li>
                    <Link to={{ pathname: "/" }}>
                      {" "}
                      {t("footerHorsConnexion.li_mecene")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/" }}>
                      {t("footerHorsConnexion.li_don")}{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__top__li">
                <h6 className="h1_trait_dessous footerTrait">
                  <Link to={{ pathname: "/partenaires" }}>
                    {t("footerHorsConnexion.title_partenaires")}
                  </Link>
                </h6>
                <br />
                <ul>
                  <li>
                    <Link to={{ pathname: "/partenaires" }}>
                      {t("footerHorsConnexion.li_decouvrir_partenaires")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/partenaires" }}>
                      {t("footerHorsConnexion.li_devenir_partenaires")}{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer__top__li">
                <h6 className="h1_trait_dessous footerTrait">
                  <a
                    href={"https://boutique.univ-paris1.fr/index.php"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("footerHorsConnexion.title_boutique")}
                  </a>
                </h6>
                <br />
                <ul>
                  <li>
                    <a
                      href={"https://boutique.univ-paris1.fr/index.php"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {t("footerHorsConnexion.li_boutique")}{" "}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer__top__li">
                <h6 className="h1_trait_dessous footerTrait">
                  <Link to={{ pathname: "/" }}>
                    {t("footerHorsConnexion.title_annuaire")}{" "}
                  </Link>
                </h6>
                <br />
                <ul>
                  <li>
                    <Link to={{ pathname: "/" }}>
                      {t("footerHorsConnexion.li_trouver_alumni")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/" }}>
                      {t("footerHorsConnexion.li_trouver_entreprise")}{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="footer__bottom">
        <div className="footer__bottom__left">
          <h6>
            <Link to={{ pathname: "/contact" }}>
              {t("footerHorsConnexion.li_bottom_contact")}{" "}
            </Link>
          </h6>
          {/* <h6>
            <Link to={{ pathname: "/aide" }}>Aide</Link>
          </h6> */}
          {/* <h6>
            <Link to={{ pathname: "/accessibilite" }}>Accessibilité</Link>
          </h6> */}
          {/* <h6>
            <Link to={{ pathname: "/gestion-cookies" }}>
              {t("footerHorsConnexion.li_bottom_cookies")}{" "}
            </Link>
          </h6> */}
          <h6>
            <Link to={{ pathname: "/mentions-legales" }}>
              {t("footerHorsConnexion.li_bottom_mentions")}{" "}
            </Link>
          </h6>
          <h6>
            <Link to={{ pathname: "/plan-du-site" }}>
              {t("footerHorsConnexion.li_bottom_plan")}{" "}
            </Link>
          </h6>
        </div>
        <div className="footer__bottom__right">
          <h6>
            Copyright <FaRegCopyright /> Réseau Alumni 2022-2025
          </h6>
          <>
            {process.env?.REACT_APP_BUILD_DATE && (
              <h6 className="build">F - {process.env.REACT_APP_BUILD_DATE}</h6>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,
  items: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
