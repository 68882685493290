import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import BtnBleuLeft from "Components/Boutons/BtnBleuLeft/btn-bleu-left";
import BtnEcouteMarche from "Components/Boutons/BtnEcouteMarche/BtnEcouteMarche";
import BtnRetour from "Components/Boutons/BtnRetour/btn-retour";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import {
  updateCompletionLevel,
  updateEducation,
} from "Redux/Actions/authActions";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { fetchGet, fetchPut, useFetchGetConditional } from "Services/api";
import crown from "assets/crown.png";
import { fr } from "date-fns/locale";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaPen, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import {
  changeOrderDate,
  formatDateWihoutDays,
} from "../../../Services/functions";
import "./FormationDiplomes.scss";
import { errorToast, successToast } from "Services/toastFunctions";

const FormationDiplomes = (props) => {
  const successUpdate = useRef(null);
  const domainData = useFetchGetConditional(
    "/domains",
    props.auth.token,
    props.secondaryTables.domainsData
  );
  const diplomaData = useFetchGetConditional(
    "/level_of_educations",
    props.auth.token,
    props.secondaryTables.levelOfEducationsData
  );

  const [errorMessageEndDate, setErrorMessageEndDate] = useState({
    message: "",
    index: null,
  });
  const [countForm, setCountForm] = useState(1);
  const [validated, setValidated] = useState(false);
  const [formations, setFormations] = useState(
    typeof props.auth.userConnected?.resume?.educations === "object"
      ? Object.values(props.auth.userConnected.resume.educations)
      : props.auth.userConnected?.resume?.educations
  );
  const [modified, setModified] = useState(false);

  const { t } = useTranslation("common");

  // Liste des id des formations supprimées (lors de la prochaine requête PUT)
  const [deletedIds, setDeletedIds] = useState([]);

  useEffect(() => {
    diplomaData.loaded &&
      domainData.loaded &&
      props.handleUpdateSecondaryTables({
        levelOfEducationsData: diplomaData.data,
        domainsData: domainData.data,
      });
  }, [diplomaData.loaded, domainData.loaded]);

  const verifyDataInstance = (i) => {
    if (
      formations[i]?.certified ||
      ((formations[i].studyLevel != null || formations[i].studyLevel != "") &&
        (formations[i].diploma != null || formations[i].diploma != "") &&
        (formations[i].school != null || formations[i].school != "") &&
        (formations[i].domain != null || formations[i].domain != "") &&
        formations[i].startDate != null &&
        (formations[i].endDate != null || formations[i].current))
    ) {
      return true;
    }
    return false;
  };

  const verifyData = () => {
    setValidated(true);
    var verifiedData = true;
    for (let index = 0; index < formations.length; index++) {
      let check = verifyDataInstance(index);
      verifiedData = verifiedData ? check : false;
    }
    return verifiedData;
  };

  const checkFormationBeforeAdd = () => {
    let verifiedData = verifyData();
    addFormation(verifiedData);
  };

  const addFormation = (verifiedData) => {
    if (verifiedData) {
      const newFormation = {
        studyLevel: "",
        diploma: null,
        domain: null,
        school: "",
        startDate: null,
        endDate: null,
        current: false,
        city: "",
        //! A supprimer ?! (non utilisée en BACK)
        idFormation: countForm,
      };
      setCountForm(countForm + 1);
      setFormations([...formations, newFormation]);
      setValidated(false);
    }
  };

  const majFormation = (e, i, champ) => {
    const newArray = [...formations];

    if (champ === "studyLevel") {
      newArray[i].studyLevel = e.target.value;
    }
    if (champ === "diploma") {
      newArray[i].diploma = e.target.value;
    }
    if (champ === "domain") {
      newArray[i].domain = e.target.value;
    }
    if (champ === "school") {
      newArray[i].school = e.target.value;
    }
    if (champ === "current") {
      newArray[i].current = e.checked;
    }
    if (champ === "startDate") {
      newArray[i].startDate = e;
    }
    if (champ === "endDate") {
      newArray[i].endDate = e;
    }
    if (champ === "city") {
      newArray[i].city = e.target.value;
    }
    setFormations(newArray);
    verifyData(i);
  };

  const supprFormation = (i, formationId) => {
    const newArray = [...formations];

    // Ajout de la formation à supprimer dans le tableau des id à supprimer
    if (formationId) setDeletedIds([...deletedIds, formationId]);
    newArray.splice(i, 1);
    setFormations(newArray);
  };

  const Valider = () => {
    let verifiedData = verifyData();
    if (verifiedData && errorMessageEndDate.message === "") {
      setModified(false);
      updateExperience();
    }
  };

  const updateExperience = async () => {
    //? Remove id de l'objet props.user et modification des dates de l'objet
    let formationsObj = [];
    for (let i = 0; i < formations.length; i++) {
      if (formations[i]?.startDate?.length < 8)
        formations[i].startDate = changeOrderDate(formations[i].startDate);
      if (formations[i]?.endDate?.length < 8)
        formations[i].endDate = changeOrderDate(formations[i].endDate);

      formationsObj.push({
        ...formations[i],
        domain: constructIRI(formations[i].domain, "domain"),
        studyLevel: constructIRI(formations[i].studyLevel, "studyLevel"),
        obtainedDiplome: constructIRI(
          formations[i]?.obtainedDiplome,
          "diplome"
        ),
        currentEtape: constructIRI(formations[i]?.currentEtape, "etape"),
      });
    }

    let educationObject = {
      educations: JSON.parse(JSON.stringify(formationsObj)),
    };

    //? Ajout des id des formations supprimées
    if (deletedIds.length > 0)
      educationObject.educations = {
        ...educationObject.educations,
        ...deletedIds.map((id) => ({ id, deleted: true })),
      };

    const cVId = props.auth.userConnected.resume.id;
    const userId = props.auth.userConnected.id;

    const updateRequest = await fetchPut(`/cvs/${cVId}`, educationObject);
    if (updateRequest.error) {
      errorToast(
        updateRequest.error.response?.data?.detail || "Une erreur est survenue"
      );
      return;
    }

    const updatedUser = await fetchGet(
      `/users/${userId}/private`,
      props.auth.token
    );
    if (updatedUser.error) {
      errorToast(
        updatedUser.error.response?.data?.detail || "Une erreur est survenue"
      );
      return;
    }
    const newExperiences = Object.values(updatedUser?.resume?.educations);
    props.handleUpdateEducation(newExperiences);
    setFormations(newExperiences);
    successToast("Votre mise à jour a bien été prise en compte");

    const completionRate = await fetchGet(
      `/users/${userId}/profile_completion_rate`
    );
    if (completionRate.error) {
      errorToast(
        completionRate.error.response?.data?.detail || "Une erreur est survenue"
      );
      return;
    }

    props.handleUpdateCompletionLevel(completionRate.data);
  };

  /**
   * Fonction qui permet de trouver un objet 'domaine' ou 'niveau de diplôme'
   * à partir d'une valuer (string avec URI ou objet directement)
   *
   * Utilisé pour les Dropdowns dans la modification des formations
   * @param {'domain'|"studyLevel"} criteria
   * @param {string|object|null} value
   * @returns {object|null}
   */
  const findObject = (criteria, value) => {
    if (typeof value === "object" && value?.label) {
      return value;
    }

    if (value) {
      if (criteria === "studyLevel") {
        return diplomaData.data.find((diplome) => diplome.label === value);
      } else {
        return domainData.data.find((secteur) => secteur.label === value);
      }
    }
    return null;
  };

  /**
   * Fonction qui permet de construire une URI à partir d'un objet ou d'une string
   * @param {string|object }value
   * @param {'domain'|"studyLevel"|"etape"|"diplome"} type
   * @returns {*|string}
   */
  const constructIRI = (value, type) => {
    const id = value?.id || value;
    if (!id) return null;
    switch (type) {
      case "domain":
        return "/api/domains/" + value.id;
      case "studyLevel":
        return "/api/level_of_educations/" + value.id;
      case "diplome":
        return "/api/education_diplomes/" + value.id;
      case "etape":
        return "/api/education_etapes/" + value.id;
    }
  };

  //? Fonction qui permet de comparer si la date de début est antérieure à la date de fin et gérer les messages d'erreurs pour l'utilisateur
  const handleDateChange = (
    dateModified,
    startDate,
    endDate,
    indexFormation
  ) => {
    if (startDate !== null && endDate !== null) {
      if (dateModified === "end") {
        majFormation(
          new Date(endDate).toLocaleDateString("en-US"),
          indexFormation,
          "endDate"
        );
      } else {
        majFormation(
          new Date(startDate).toLocaleDateString("en-US"),
          indexFormation,
          "startDate"
        );
      }
      if (new Date(startDate) < new Date(endDate)) {
        setErrorMessageEndDate({
          index: indexFormation,
          message: "",
        });
      } else {
        setErrorMessageEndDate({
          message: "La date de début doit être antérieure à la date de fin",
          index: indexFormation,
        });
      }
    } else if (startDate !== null) {
      majFormation(
        new Date(startDate).toLocaleDateString("en-US"),
        indexFormation,
        "startDate"
      );
    } else {
      setErrorMessageEndDate({
        message: "Veuillez remplir une date de début d'expérience",
        index: indexFormation,
      });
    }
  };
  return (
    <div>
      <Toast ref={successUpdate} />
      {props.auth.isConnected && (
        <div className="article_actu">
          <NavBarTab
            id="desktop"
            select="profiledit"
            activeIndex={props.activeIndex}
            items={props.items}
          />
          <BtnEcouteMarche items={props.items} />

          <div className="titre_de_page">
            <h1 className="h1_trait_dessus">
              {t("profilEdition.studiesDegrees.header")}
            </h1>
            <BtnRetour />
          </div>

          <div className="formation_container">
            <div className="formation_list" key={modified}>
              {formations.length > 0 &&
              domainData.loaded &&
              diplomaData.loaded &&
              modified
                ? formations
                    .filter((formation) => !formation?.certified)
                    .map((formation, indexFormations) => {
                      const actualIndex = formations.findIndex(
                        (f) => f === formation
                      );
                      return (
                        <div
                          className={"formation_form"}
                          key={
                            formation?.id
                              ? "indexFormations " + formation.id
                              : "new " + indexFormations
                          }
                        >
                          <div className="formation_form_header">
                            <h1>Formation {indexFormations + 1}</h1>
                            <BtnBlanc
                              btnAction={() => {
                                supprFormation(actualIndex, formation.id);
                              }}
                            >
                              <FaTrash />
                            </BtnBlanc>
                          </div>
                          <div className="formation_form_columns">
                            <div className="field" style={{ width: "100%" }}>
                              <h2>
                                {t(
                                  "profilEdition.studiesDegrees.formationTitle"
                                )}{" "}
                                *
                              </h2>
                              <InputText
                                id="diploma"
                                placeholder={t(
                                  "profilEdition.studiesDegrees.formationTitlePlaceholder"
                                )}
                                key={formation}
                                value={formation.diploma}
                                onChange={(e) =>
                                  majFormation(e, actualIndex, "diploma")
                                }
                                required
                              />
                              {(formation.diploma == "" ||
                                formation.diploma == null) &&
                                validated && (
                                  <label className="validation">
                                    {t(
                                      "profilEdition.studiesDegrees.formationTitleValidation"
                                    )}
                                  </label>
                                )}
                            </div>
                          </div>
                          <div className="formation_form_columns">
                            <div className="field">
                              <h2>
                                {t("profilEdition.studiesDegrees.startingDate")}{" "}
                                *
                              </h2>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={fr}
                              >
                                <DatePicker
                                  label={t(
                                    "profilEdition.studiesDegrees.startingDate"
                                  )}
                                  selected={
                                    formation.startDate === null
                                      ? "09/2022"
                                      : formatDateWihoutDays(
                                          formation.startDate
                                        )
                                  }
                                  onChange={(date) =>
                                    handleDateChange(
                                      "start",
                                      date,
                                      formation.endDate,
                                      actualIndex
                                    )
                                  }
                                  labelFunc={() =>
                                    formation.startDate !== "" &&
                                    formation.startDate !== null
                                      ? formatDateWihoutDays(
                                          formation.startDate
                                        )
                                      : "09/2019..."
                                  }
                                  views={["year", "month"]}
                                  cancelLabel="Annuler"
                                />
                              </MuiPickersUtilsProvider>
                              {formation.startDate == null && validated && (
                                <label className="validation">
                                  {t(
                                    "profilEdition.studiesDegrees.startingDateValidation"
                                  )}
                                </label>
                              )}
                            </div>
                            {!formation.current && (
                              <div className="field">
                                <h2>
                                  {t("profilEdition.studiesDegrees.endingDate")}{" "}
                                  *
                                </h2>
                                <MuiPickersUtilsProvider
                                  utils={DateFnsUtils}
                                  locale={fr}
                                >
                                  <DatePicker
                                    label={t(
                                      "profilEdition.studiesDegrees.endingDate"
                                    )}
                                    placeholderText="09/2022"
                                    selected={
                                      formation.endDate === ""
                                        ? formatDateWihoutDays(Date.now())
                                        : formatDateWihoutDays(
                                            formation.endDate
                                          )
                                    }
                                    onChange={(date) =>
                                      handleDateChange(
                                        "end",
                                        formation.startDate,
                                        date,
                                        actualIndex
                                      )
                                    }
                                    maxDate={Date.now()}
                                    labelFunc={() =>
                                      formation.endDate !== "" &&
                                      formation.endDate !== null
                                        ? formatDateWihoutDays(
                                            formation.endDate
                                          )
                                        : "09/2019..."
                                    }
                                    views={["year", "month"]}
                                    cancelLabel="Annuler"
                                  />
                                </MuiPickersUtilsProvider>
                                {formation.endDate == null && validated ? (
                                  <label className="validation">
                                    {t(
                                      "profilEdition.studiesDegrees.endingDateValidation"
                                    )}
                                  </label>
                                ) : (
                                  ""
                                )}
                                {errorMessageEndDate.message !== "" &&
                                  errorMessageEndDate.index == actualIndex && (
                                    <p className="errorMessageEndDate">
                                      {errorMessageEndDate.message}
                                    </p>
                                  )}
                              </div>
                            )}
                          </div>
                          <div className="formation_form_columns">
                            <div className="field">
                              <h2>
                                {t("profilEdition.studiesDegrees.university")} *
                              </h2>
                              <InputText
                                id="school"
                                placeholder={t(
                                  "profilEdition.studiesDegrees.universityPlaceholder"
                                )}
                                key={indexFormations}
                                value={formation.school}
                                onChange={(e) =>
                                  majFormation(e, actualIndex, "school")
                                }
                                required
                              />
                              {formation.school == "" && validated && (
                                <label className="validation">
                                  {t(
                                    "profilEdition.studiesDegrees.universityValidation"
                                  )}
                                </label>
                              )}
                            </div>
                            <div className="field">
                              <h2>
                                {t("profilEdition.studiesDegrees.city")} *
                              </h2>
                              <InputText
                                id="title"
                                placeholder="Paris"
                                key={indexFormations}
                                value={formation.city}
                                onChange={(e) =>
                                  majFormation(e, actualIndex, "city")
                                }
                                // options={diplomaData}
                                required
                              />
                              {formation.city == "" && validated && (
                                <label className="validation">
                                  {t(
                                    "profilEdition.studiesDegrees.cityValidation"
                                  )}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="formation_form_columns">
                            <div className="field">
                              <h2>
                                {t("profilEdition.studiesDegrees.degreeLevel")}
                              </h2>
                              <Dropdown
                                id="studyLevel"
                                placeholder="Bac+2..."
                                key={indexFormations}
                                value={findObject(
                                  "studyLevel",
                                  formation.studyLevel
                                )}
                                onChange={(e) =>
                                  majFormation(e, actualIndex, "studyLevel")
                                }
                                options={diplomaData.data}
                                required
                              />
                              {(formation.studyLevel == null ||
                                formation.studyLevel == "") &&
                                validated && (
                                  <label className="validation">
                                    {t(
                                      "profilEdition.studiesDegrees.degreeLevelValidation"
                                    )}
                                  </label>
                                )}
                            </div>
                            <div className="field">
                              <h2>
                                {t("profilEdition.studiesDegrees.sector")} *
                              </h2>
                              <Dropdown
                                id="domain"
                                placeholder={t(
                                  "profilEdition.studiesDegrees.sectorPlaceholder"
                                )}
                                key={indexFormations}
                                value={findObject("domain", formation.domain)}
                                options={domainData.data}
                                onChange={(e) =>
                                  majFormation(e, actualIndex, "domain")
                                }
                                required
                              />
                              {formation.domain == null && validated && (
                                <label className="validation">
                                  {t(
                                    "profilEdition.studiesDegrees.sectorValidation"
                                  )}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="formation_form_columns">
                            <Checkbox
                              label="current"
                              key={indexFormations}
                              checked={formation.current}
                              onChange={(e) =>
                                majFormation(e, actualIndex, "current")
                              }
                            />

                            <label className="inline-text" htmlFor="current">
                              {t(
                                "profilEdition.studiesDegrees.ongoingFormation"
                              )}
                            </label>
                          </div>
                          {/* formationVerif */}
                          <div></div>
                        </div>
                      );
                    })
                : formations
                    // Tri des formations par :
                    // 1- Formation en cours
                    // 2- Date de fin
                    // 3- Date de début
                    ?.sort((a, b) => {
                      if (b?.current - a?.current !== 0) {
                        return b?.current - a?.current;
                      } else if (
                        new Date(b?.endDate || Date.now()) -
                          new Date(a?.endDate || Date.now()) !==
                        0
                      ) {
                        return (
                          new Date(b?.endDate || Date.now()) -
                          new Date(a?.endDate || Date.now())
                        );
                      } else {
                        return new Date(b?.startDate) - new Date(a?.startDate);
                      }
                    })
                    ?.map((formation, indexFormations) => {
                      if (formation?.certified) {
                        return (
                          <div
                            className="formation_card"
                            key={"formation show " + indexFormations}
                          >
                            <img
                              src={crown}
                              alt="couronne"
                              className="formation_card_certified"
                              title="Certification de l'université"
                            />
                            <h4>
                              {formation.current
                                ? formation?.currentEtape?.label
                                : formation?.obtainedDiplome?.label}
                            </h4>
                            <p>
                              {formation.current
                                ? t(
                                    "profilEdition.professionalExperiences.inProgress"
                                  )
                                : formation?.startDate
                                ? new Date(
                                    formation?.startDate
                                  )?.getFullYear() +
                                  " - " +
                                  new Date(formation?.endDate)?.getFullYear()
                                : new Date(formation?.endDate)?.getFullYear()}
                            </p>
                            <p>
                              {t(
                                "profilEdition.professionalExperiences.certifiedFormation"
                              )}
                            </p>
                            <div className="profil_show__container__article__container__experience__item">
                              <p>Université Paris 1 Panthéon Sorbonne</p>
                              <br />
                              {formations[indexFormations].domain?.label}
                            </div>
                          </div>
                        );
                      } else
                        return (
                          <div
                            className="formation_card"
                            key={"formation show " + indexFormations}
                          >
                            <h4>{formations[indexFormations].diploma}</h4>
                            <p>
                              {formatDateWihoutDays(
                                formations[indexFormations].startDate
                              )}{" "}
                              -{" "}
                              {formations[indexFormations].current
                                ? t(
                                    "profilEdition.professionalExperiences.today"
                                  )
                                : formatDateWihoutDays(
                                    formations[indexFormations].endDate
                                  )}
                            </p>
                            <div className="formation_card_item">
                              <p>
                                {formations[indexFormations].school} -{" "}
                                {formations[indexFormations].city}
                              </p>
                              <p>{formations[indexFormations].domain?.label}</p>
                              <p>
                                <span>
                                  Niveau
                                  <span> </span>
                                  {formations[indexFormations].studyLevel
                                    ?.label ||
                                    formations[indexFormations].studyLevel}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                    })}

              <div
                className="formation_list_boutons"
                id="formation_list_boutons"
              >
                {modified ? (
                  <>
                    <div
                      className="formation_list_boutons-add"
                      onClick={() => checkFormationBeforeAdd()}
                    >
                      <i className="pi pi-plus-circle" size="2rem" />
                      <label>
                        {t("profilEdition.studiesDegrees.addFormation")}
                      </label>
                    </div>
                    <div>
                      <BtnBleuLeft
                        btnTexte={t("profilEdition.validate")}
                        btnAction={Valider}
                      >
                        <FaCheck />
                      </BtnBleuLeft>
                    </div>
                  </>
                ) : (
                  <BtnBlancLeft
                    btnTexte={t("profilEdition.edit")}
                    btnAction={() => setModified(true)}
                  >
                    <FaPen />
                  </BtnBlancLeft>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

FormationDiplomes.propTypes = {
  auth: PropTypes.object,
  secondaryTables: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  handleUpdateEducation: PropTypes.func,
  handleUpdateCompletionLevel: PropTypes.func,
  handleUpdateSecondaryTables: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEducation: (value) => {
    dispatch(updateEducation(value));
  },
  handleUpdateCompletionLevel: (value) => {
    dispatch(updateCompletionLevel(value));
  },
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormationDiplomes);
