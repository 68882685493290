import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Brouillon from "Components/Brouillons/Brouillon";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import Loader from "Components/Loader/loader";
import Modal from "Components/Modal/Modal";
import QuillOptions from "Components/QuillOptions/QuillOptions";
import Margin from "Components/Stats/Margin";
import { updateMenu } from "Redux/Actions/menuActions";
import { updateOffers } from "Redux/Actions/offersActions";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { fetchGet, fetchPost } from "Services/api";
import { upperCaseFirstLetter } from "Services/functions";
import { errorToast, successToast } from "Services/toastFunctions";
import axios from "axios";
import DOMPurify from "dompurify";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";
import { classNames } from "primereact/utils";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaWheelchair } from "react-icons/fa";
import { IoMdDesktop, IoMdHome } from "react-icons/io";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import data from "../../../assets/countries";
import SliderSalaire from "../SliderSalaire/sliderSalaire";
import "./DepotOffre.scss";
import PrevisualisationOffre from "./PrevisualisationOffre/PrevisualisationOffre";

/**
 * Permet d'afficher la page de dépôt d'offres
 * Accessible pour tous les profils une fois connectés
 */
const DepotOffre = (props) => {
  const { t } = useTranslation("common");

  // ------- VARIABLES GESTION IMAGES ----------
  const [currentOffer, setCurrentOffer] = useState(null);
  // La photo vient-elle de la photothèque ?
  const [phototheque, setPhototheque] = useState(true);
  // Contiendra l'url de l'image choisie (upload ou photothèque)
  const [imageFile, setImageFile] = useState("");
  // ID de l'image choisie (phototheque)
  const [imageId, setImageId] = useState("");

  // Contiendra le fichier upload par l'utilisateur
  const [image, setImage] = useState("");
  // ------- TOASTS ----------
  // Toast de succès d'upload d'image
  const uploadToast = useRef(null);
  // Toast de suppression de l'image upload
  const cancelToast = useRef(null);
  // Toast envoi formualire
  const publishToast = useRef(null);

  const [readOnlySalary, setReadOnlySalary] = useState(false);

  const [displaySalary, setDisplaySalary] = useState(false);
  const [waitingLoader, setWaitingLoader] = useState(false);
  // Valeurs par défaut du formulaire
  let defaultValues = {
    title: "",
    description: "",
    city: "",
    country: "France",
    department: "",
    postedAt: null,
    publishDuration: null,
    domains: null,
    salary: [-5, -5],
    typeOfContract: null,
    companyName: "",
    companyDescription: "",
    direct: true,
    public: true,
    postedBy: "",
    postedByPartner: true,
    createdByUser: "",
    viewsNumber: 0,
    numberOfCandidatures: 0,
    experience: "",
    offerStatus: "",
    offerCategory: undefined,
    dateModified: null,
    typeOfOffer: null,
    sector: null,
    levelOfEducations: null,
    accessibleForDisabled: false,
    accommodation: false,
    remoteWorking: false,
    companyUrl: null,
    urlCandidature: null,
  };

  // Variables de la librairie react-hook-form
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({ defaultValues });

  // Liste des durées d'affichage d'une offre
  const delaiData = [
    { name: "1 mois", label: 1 },
    { name: "2 mois", label: 2 },
    { name: "3 mois", label: 3 },
    { name: "4 mois", label: 4 },
    { name: "5 mois", label: 5 },
    { name: "6 mois", label: 6 },
  ];

  // type de contrats
  const [typeOfContract, setTypeOfContract] = useState(null);

  const [isModifying, setIsModifying] = useState(false);

  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    props.secondaryTables.sectorOfOffersData?.length > 0 &&
      props.secondaryTables.typeOfContractsData?.length > 0 &&
      props.secondaryTables.offerCategoriesData?.length > 0 &&
      props.secondaryTables.levelOfEducationsData?.length > 0 &&
      props.secondaryTables.domainsData?.length > 0 &&
      setIsLoader(false);
  }, [
    props.secondaryTables.sectorOfOffersData,
    props.secondaryTables.typeOfContractsData,
    props.secondaryTables.offerCategoriesData,
    props.secondaryTables.levelOfEducationsData,
    props.secondaryTables.domainsData,
  ]);

  /**
   * Hook qui met le champ de typeOfContract à null en fonction de
   * certaines catégories de contrats
   */
  useEffect(() => {
    if (
      getValues().offerCategory &&
      (getValues().offerCategory.label === "STAGE" ||
        getValues().offerCategory.label === "VIE/VIA" ||
        getValues().offerCategory.label === "Service Civique")
    ) {
      setValue("typeOfContract", undefined);
    }
  }, [watch("offerCategory")]);

  /**
   * Hook qui rajoute un champ name aux types de contrats
   *
   * Ce champ sera soit identique à label, soit le champ 'label'
   * avec une majuscule au début et le reste en minuscule, pour une meilleur
   * visibilité et user-experience sur le formulaire
   *
   * Exemple : 'CONTRAT DE PROFESSIONNALISATION' -> 'Contrat de professionnalisation'
   */
  useEffect(() => {
    if (props.secondaryTables.typeOfContractsData.length > 0) {
      var tempTypeOfContract = null;
      tempTypeOfContract = props.secondaryTables.typeOfContractsData.map(
        (typeContrat) => typeContrat
      );
      tempTypeOfContract.forEach((type) => {
        type.name =
          type.label == "CDI" ||
          type.label == "CDD" ||
          type.label == "INTERIM" ||
          type.label == "CIFRE"
            ? type.label
            : upperCaseFirstLetter(type.label, true);
      });
      setTypeOfContract(tempTypeOfContract);
    }
  }, [props.secondaryTables.typeOfContractsData]);

  useEffect(async () => {
    if (
      props.secondaryTables.sectorOfOffersData.length > 0 &&
      props.secondaryTables.domainsData.length > 0 &&
      props.secondaryTables.offerCategoriesData.length > 0 &&
      props.secondaryTables.levelOfEducationsData.length > 0 &&
      typeOfContract &&
      props.offerId
    ) {
      setIsLoader(true);
      let url = `/offers/${props.offerId}`;
      if (props.recruteur) url += "/recruiter";
      const offer = await fetchGet(url, props.auth.token);
      if (!offer) {
        props.auth.toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Une erreur est survenue lors de la récupération de l'offre",
          life: 5000,
        });
        props.setVisible && props.setVisible(false);
        return;
      }
      setCurrentOffer(offer);
      fillFormFromDraft(offer);
      setIsModifying(true);
      setIsLoader(false);
    }
  }, [
    props.secondaryTables.sectorOfOffersData.length,
    props.secondaryTables.domainsData.length,
    props.secondaryTables.offerCategoriesData.length,
    props.secondaryTables.levelOfEducationsData.length,
    typeOfContract,
    props.offerId,
  ]);

  useEffect(() => {
    props.offerId && setPhototheque(false);
    if (!props.offerId && delaiData && defaultValues.publishDuration === null)
      setValue("publishDuration", delaiData[0]);
  }, []);

  // Variable du state pour la liste des contacts
  const [contact, setContact] = useState([
    {
      email: "",
      phone: "",
    },
  ]);

  /**
   * Fonction qui ajoute un contact à la liste des contacts
   */
  const addContact = () => {
    const newContact = new Object();
    newContact.email = "";
    newContact.phone = "";
    const newArray = [...contact];
    newArray.push(newContact);
    setContact(newArray);
  };

  /**
   *
   * @param {Object} e - Event qui trigger lors de l'édition d'un des champs contact
   * @param {Object} e.target - Objet contenant les données du champ édité
   * @param {String} e.target.value - Valeur en String du champ déterminé par {@link champ}
   * @param {*} i - Index du contact à modifier
   * @param {*} champ - Nom du champ à modifier : {email, phone}
   */
  const majContact = (e, i, champ) => {
    const newArray = [...contact];
    if (champ == "email") {
      newArray[i].email = e.target.value;
    } else {
      newArray[i].phone = e.target.value;
    }

    setContact(newArray);
    verifyDepotOffres(i);
  };

  // variables des popin
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalBrouillon, setVisibleModalBrouillon] = useState(false);

  // vérification du remplissage des champs
  const [verified, setVerified] = useState(null);

  /**
   * Fonction servant à vérifier si au mois un des champs contact est rempli
   *
   * Si au moins un est rempli, on met {@link verified} à true, sinon false
   * @param {int} i - index du contact à vérifier
   */
  const verifyDepotOffres = (i) => {
    if (
      // vérification des champs email et téléphone pour chaque tableau contact
      contact[i].email != "" ||
      contact[i].phone != "" ||
      // vérfiication si le lien de candidature est renseigné
      (getValues().urlCandidature !== "" && getValues().urlCandidature)
    ) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };

  /**
   * Fonction servant à vérifier le formulaire avant de le soumettre
   *
   * c.f {@link verifyDepotOffres}
   */
  const verify = () => {
    for (let index = 0; index < contact.length; index++) {
      verifyDepotOffres(index);
    }
  };

  /**
   * Hook qui va vérifier le formulaire à chaque modification des champs liés à la partie contact
   */
  useEffect(() => {
    verify();
  }, [contact, watch("urlCandidature"), watch("companyUrl")]);

  /**
   * Fonction qui renvoie un élément JSX qui affichera une balise erreur sur le site, sur le champs concerné
   * @param {string} name - nom du champ qui a une erreur
   * @returns {JSX} - message d'erreur en format JSX pour être affiché
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const postForm = async (data) => {
    // URL de la requête POST
    let url = "/offers/request/validation";
    // On crée un FormData qui sera envoyé au serveur
    let formData = new FormData();
    // On ajoute les données du formulaire au formData
    for (let key in data) formData.append(key, data[key]);
    // On envoie le formData au serveur
    const createdOffer = await fetchPost(url, formData, props.auth.token);
    setWaitingLoader(false);
    if (createdOffer.error) {
      const err = createdOffer.error.response;
      if (err?.statusText === "Unprocessable Entity" || err?.status == 422) {
        errorToast(
          err?.data?.detail ||
            "L'image doit être au format paysage et ne doit pas dépasser 1Mo"
        );
      } else {
        errorToast(err?.data?.detail || "Une erreur est survenue.");
      }
      return;
    }
    // Gestion du succès
    clearForm();
    setVisibleModal(true);
  };

  const putForm = async (data) => {
    // URL de la requête POST
    let url = props.recruteur
      ? `https://recruteurs.reseaupro.unif.fr/api/ext/offers/${props.offerId}`
      : `${process.env.REACT_APP_BASE_URL_API}/offers/${props.offerId}`;
    currentOffer?.state.statusLabel === "Brouillon"
      ? (url += `/edit/draft`)
      : currentOffer?.state.statusLabel === "Refusé"
      ? (url += `/request/validation`)
      : (url += `/edit/publish`);

    if (currentOffer.transmitter.name === "Recruteur") {
      url += "/recruiter";
    }

    // On crée un FormData qui sera envoyé au serveur
    let formData = new FormData();
    // On ajoute les données du formulaire au formData
    for (let key in data) formData.append(key, data[key]);
    // On envoie le formData au serveur
    const editedOffer = await fetchPost(url, formData, props.auth.token);
    setWaitingLoader(false);
    if (editedOffer.error) {
      const errorMessage = editedOffer.error?.response?.data?.detail;
      errorToast(errorMessage || "Une erreur est survenue");
      return;
    }

    // Gestion du succès
    if (props.offers.dataFromPagination?.data) {
      // Si props.setOffer est défini, on met à jour l'offre dans le state (modification depuis OffreDetail)
      if (props.setOffre) props.setOffre(editedOffer.data);
      let tempArray = props.offers.dataFromPagination;
      tempArray.data = tempArray.data.map((offer) => {
        if (offer.id === editedOffer.data.id) return editedOffer.data;
        else return offer;
      });
      props.handleUpdateOffers({ dataFromPagination: tempArray });
    } else {
      props.handleUpdateOffers({
        dataFromPagination: {
          data: [editedOffer.data],
          loaded: true,
          page: 1,
          setPage: null,
          totalItems: 1,
        },
      });
    }
    successToast("L'offre a bien été modifiée");
    setVisibleModal(true);
    clearForm();
    props.setVisible(false);
  };

  /**
   * Remplissage de la key image selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId.toString();
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  /**
   * Remplissage de la key contact selon certains filtre
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillContacts = (data) => {
    let contacts = contact.filter(
      (object) => object.email != "" || object.phone != ""
    );
    data = {
      ...data,
      contacts:
        contacts === undefined || contacts.length === 0
          ? null
          : JSON.stringify(contacts),
    };
    return data;
  };

  /**
   * Remplissage des keys ayant une relation avec des autres tables
   * @param {Object} data - données pour la requêtes
   * @param {bool} draft - true si on veut créer un brouillon, false si on veut créer une offre standard
   * @returns {Object}
   */
  const fillRelations = (data) => {
    let contratObject = data.typeOfContract
      ? "/api/type_of_contracts/" +
        typeOfContract.find((object) => object.id === data.typeOfContract.id).id
      : null;
    let sectorObject = data.sector
      ? "/api/sector_of_offers/" +
        props.secondaryTables.sectorOfOffersData.find(
          (object) => object.id === data.sector.id
        ).id
      : null;
    let user = "/api/users/" + props.auth.userConnected.id;
    let etudesObject = [];
    let domainsObject = [];
    if (data.levelOfEducations)
      data.levelOfEducations.forEach((element) => {
        etudesObject.push("/api/level_of_educations/" + element.id);
      });
    if (data.domains)
      data.domains.forEach((element) => {
        domainsObject.push("/api/domains/" + element.id);
      });
    let offerCategoryObject = data.offerCategory
      ? "/api/offer_categories/" +
        props.secondaryTables.offerCategoriesData.find(
          (object) => object.label === data.offerCategory.label
        ).id
      : null;
    data = {
      ...data,
      domains: JSON.stringify(domainsObject),
      typeOfContract: contratObject,
      sector: sectorObject,
      offerCategory: offerCategoryObject,
      levelOfEducations: JSON.stringify(etudesObject),
      createdByUser: user,
      postedBy: user,
    };

    delete data.handicapes;
    return data;
  };

  const fillBooleans = (data) => {
    data = {
      ...data,
      // noms à revoir après intégration back !!!
      // teletravail: da,
      // hebergement: hebergement,
      //
    };
    return data;
  };

  /**
   *  Fonction vérifiant les données, et supprimant celles nulles
   * @param {FormData} data - données du formulaire pour la requête en format FormData
   * @returns {FormData} données du formulaire pour la requête en format FormData, sans les champs null
   */
  const checkNulls = (data) => {
    for (let key in data) {
      if (
        data[key] === null ||
        (Array.isArray(data[key]) && data[key].length === 0)
      )
        delete data[key];
    }
    if (data.companyUrl === "") delete data.companyUrl;
    // if (data.urlCandidature === "") delete data.urlCandidature;
    return data;
  };

  /**
   * Fonction qui va remplir un object avec tous les éléments nécessaires pour la requête
   * @param {Object} data - données du formulaire pour la requête
   * @param {string} formType - type de formulaire (multipart / json)
   * @returns données du formulaire pour la requête
   */
  const setFields = (data, formType = "multipart") => {
    data = {
      ...data,
      city: data.city,
      country: data.country,
      department: data.department,
      description: DOMPurify.sanitize(data.description),
      experience: DOMPurify.sanitize(data.experience),
      companyDescription: DOMPurify.sanitize(data.companyDescription),
      direct: false,
      public: false,
      postedByPartner: false,
      viewsNumber: 0,
      numberOfCandidatures: 0,
      // TO DO : rajouter télétravail et hébergement possible dans la base de données back
    };
    if (displaySalary) {
      data.minSalary = data.minSalary || data.salary[0] || null;
      data.maxSalary = data.maxSalary || data.salary[1] || null;
    }
    delete data.salary;
    data.publishDuration =
      data.publishDuration && data.publishDuration.label
        ? data.publishDuration.label
        : 0;
    data = fillImage(data);
    data = fillContacts(data);
    data = fillRelations(data);
    data = fillBooleans(data);

    if (formType === "json") {
      data.contacts = !data.contacts ? [] : JSON.parse(data.contacts);
      delete data.imageStockId;
    } else {
      data = checkNulls(data);
    }
    if (isModifying) {
      data.createdByUser = currentOffer?.createdByUser;
    }
    return data;
  };

  /**
   * Hook qui va gérer la soumission du formulaire
   * @param {Object} data - données obtenues du formulaire après appui sur le bouton "Envoyer"
   *
   * {@link setFields}
   * {@link postForm}
   */
  const onSubmit = (data) => {
    verify();
    if (verified) {
      setWaitingLoader(true);
      data = setFields(data);
      if (isModifying) {
        putForm(data);
      } else {
        postForm(data);
      }
    }
  };

  /**
   * Fonction qui va remplir le formulaire avec les données récupérées du brouillon
   * @param {Object} draft - brouillon d'une offre
   */
  const fillFormFromDraft = (draft) => {
    // remplissage du champ niveaux d'éducations
    let levels = [];
    let domains = [];
    if (draft.levelOfEducations && draft.levelOfEducations.length > 0)
      draft.levelOfEducations.forEach((element) => {
        levels.push(
          props.secondaryTables.levelOfEducationsData.find(
            (object) => object.id === element.id
          )
        );
      });
    if (draft.domains && draft.domains.length > 0)
      draft.domains.forEach((element) => {
        domains.push(
          props.secondaryTables.domainsData.find(
            (object) => object.id === element.id
          )
        );
      });
    // Réinitialisation des champs du formulaire avec les données obtenues du brouillon
    reset({
      title: draft.title,
      city: draft.city,
      country: draft.country,
      department: draft.department,
      companyName: draft.companyName,
      companyDescription: draft.companyDescription,
      companyUrl: draft.companyUrl,
      urlCandidature: draft.urlCandidature,
      imageUrl: draft.imageUrl,
      publishDuration: {
        name: draft.publishDuration + " mois",
        label: draft.publishDuration,
      },
      sector: draft.sector
        ? props.secondaryTables.sectorOfOffersData.find(
            (object) => object.id === draft.sector.id
          )
        : undefined,
      typeOfContract: draft.typeOfContract
        ? typeOfContract?.find(
            (object) => object.id === draft.typeOfContract?.id
          )
        : undefined,
      offerCategory: draft.offerCategory
        ? props.secondaryTables.offerCategoriesData.find(
            (object) => object.id === draft.offerCategory.id
          )
        : undefined,
      levelOfEducations: levels,
      domains: domains,
      accessibleForDisabled: draft.accessibleForDisabled,
      accommodation: draft.accommodation,
      remoteWorking: draft.remoteWorking,
      experience: draft.experience,
      description: draft.description,
    });

    if (
      draft.minSalary &&
      draft.maxSalary &&
      !isNaN(draft.minSalary) &&
      !isNaN(draft.maxSalary)
    ) {
      setValue("salary", [draft.minSalary, draft.maxSalary]);
      setDisplaySalary(true);
      setReadOnlySalary(true);
    } else {
      setValue("salary", [-5, -5]);
      setDisplaySalary(false);
      setReadOnlySalary(false);
    }

    // remplissage des champs de contacts
    let tempContacts = [];
    if (draft.contacts && Object.keys(draft.contacts).length > 0) {
      if (!Array.isArray(draft.contacts)) {
        for (let key in draft.contacts) {
          tempContacts.push({
            email: draft.contacts[key].email,
            phone: draft.contacts[key].phone,
          });
        }
      } else {
        draft.contacts.forEach((contact) => {
          tempContacts.push({
            email: contact.email,
            phone: contact.phone,
          });
        });
      }
    } else
      tempContacts.push({
        email: "",
        phone: "",
      });
    setContact(tempContacts.reverse());
  };

  /**
   * Fonction qui va renvoyer le JSX nécessaire à l'affichage du champ typeOfContract du formulaire.
   *
   * Son affichage dépendra des props en paramètres.
   * @param {Object} props - props du composant
   * @param {boolean} props.disabled - true si le champ est désactivé, false sinon
   * @param {string} props.placeholder - texte à afficher dans le champ lorsque vide
   * @param {Object[]} props.options - liste des options du champ, selon la catégorie de contrat
   * @returns {JSX}
   */
  function TypeOfContractField(props) {
    return (
      <div className="question">
        <h2
          htmlFor="typeOfContract"
          className={classNames({ "p-error": errors.offerCategory })}
        >
          Type de contrat de l&apos;offre *
        </h2>
        <Controller
          name="typeOfContract"
          control={control}
          rules={{ required: "Le type de contrat est requis" }}
          render={({ field, fieldState }) => (
            <Dropdown
              id={field.name}
              {...field}
              // eslint-disable-next-line react/prop-types
              disabled={props.disabled}
              // eslint-disable-next-line react/prop-types
              options={props.options}
              optionLabel="name"
              // eslint-disable-next-line react/prop-types
              placeholder={props.placeholder}
              className={
                (classNames({
                  "p-invalid": fieldState.invalid,
                }),
                "box__input")
              }
            />
          )}
        />
        {getFormErrorMessage("typeOfContract")}
      </div>
    );
  }

  const navigateToMyOffers = () => {
    props.handleUpdateItems({
      activeItemOffre: props.items.itemsOffre.findIndex(
        (v) => v.label == "Gérer mes dépôts d'offres"
      ),
    });
  };

  const clearForm = () => {
    setDisplaySalary(false);
    reset({
      title: "",
      description: "",
    });
    setContact([{ email: "", phone: "" }]);
    setImage("");
    setImageFile("");
    setImageId("");
    setPhototheque(true);
  };

  const [isVisibleCity, setIsVisibleCity] = useState(false);
  const [listOfCity, setListOfCity] = useState([]);
  const [currentCountry, setCurrentCountry] = useState("France");

  const getCity = (city) => {
    let listOfCities = [];
    axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${city}&type=municipality&autocomplete=1`
      )
      .then((res) => {
        res.data.features.forEach((city) =>
          listOfCities.push([city.properties.label, city.properties.context])
        );
        setListOfCity(listOfCities);
      });
  };

  const modifyDraft = () => {
    let data = getValues();
    verify();
    data = setFields(data);
    putForm(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isLoader ? (
        <div className="depot_offre_formulaire">
          <Toast ref={uploadToast} />
          <Toast ref={cancelToast} />
          <Toast ref={publishToast} />
          <div className="depot_offre_formulaire__title">
            <h1 className="h1_trait_dessus">{t("offerItem.createOffer")}</h1>
            <Brouillon
              path="/offers"
              create="/create"
              setFields={setFields}
              formValues={getValues}
              fillFormFromDraft={fillFormFromDraft}
            />
          </div>

          <div className="depot_offre_formulaire__container">
            <div className="depot_columns">
              <div className="question">
                <h2
                  htmlFor="title"
                  className={classNames({ "p-error": errors.title })}
                >
                  Titre de l&apos;offre *
                </h2>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Le titre de l'offre est requis" }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      placeholder={t(
                        "offresApresConnexionPage.depotTitlePlaceHolder"
                      )}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("title")}
              </div>
              <div className="question">
                <h2
                  htmlFor="companyName"
                  className={classNames({ "p-error": errors.companyName })}
                >
                  {t("offresApresConnexionPage.depotName")}
                </h2>
                <Controller
                  name="companyName"
                  control={control}
                  rules={{
                    required: "Le nom de l'entreprise est requis",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      placeholder={t(
                        "offresApresConnexionPage.depotNamePlaceHolder"
                      )}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("companyName")}
              </div>
            </div>

            <div className="depot_columns">
              <div className="question">
                <h2
                  htmlFor="sector"
                  className={classNames({ "p-error": errors.title })}
                >
                  Secteur d&apos;activité de l&apos;entreprise *
                </h2>
                {props.secondaryTables.sectorOfOffersData?.length > 0 ? (
                  <>
                    <Controller
                      name="sector"
                      control={control}
                      rules={{ required: "Le secteur d'activité est requis" }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={props.secondaryTables.sectorOfOffersData?.sort(
                            (a, b) => {
                              return a.label.localeCompare(b.label);
                            }
                          )}
                          optionLabel="label"
                          placeholder={t(
                            "offresApresConnexionPage.depotSecteurPlaceHolder"
                          )}
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "box__input")
                          }
                        />
                      )}
                    />
                    {getFormErrorMessage("sector")}
                  </>
                ) : (
                  <>
                    <Loader />
                    {fetchGet("/sector_of_offers").then((res) => {
                      props.handleUpdateSecondaryTables({
                        sectorOfOffersData: res.data,
                      });
                    })}
                  </>
                )}
              </div>

              <div className="question">
                <h2
                  htmlFor="domains"
                  className={classNames({ "p-error": errors.domains })}
                >
                  {t("offresApresConnexionPage.depotDomaine")}{" "}
                </h2>
                <Controller
                  name="domains"
                  control={control}
                  rules={{ required: "Le domaine de l'offre est requis" }}
                  render={({ field, fieldState }) => (
                    <MultiSelect
                      appendTo={document.getElementById("app")}
                      id={field.name}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={props.secondaryTables.domainsData}
                      optionLabel="label"
                      placeholder={t(
                        "offresApresConnexionPage.depotDomainePlaceHolder"
                      )}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "box__input")
                      }
                      maxSelectedLabels={3}
                      selectedItemsLabel={`${
                        getValues("domains")?.length
                      } options sélectionnées`}
                    />
                  )}
                />
                {getFormErrorMessage("domains")}
              </div>
            </div>
            <div className="depot_columns">
              {props.secondaryTables.offerCategoriesData.length > 0 ? (
                <div className="question">
                  <h2
                    htmlFor="offerCategory"
                    className={classNames({ "p-error": errors.offerCategory })}
                  >
                    {t("offresApresConnexionPage.depotTypeContrat")}
                  </h2>
                  <Controller
                    name="offerCategory"
                    control={control}
                    rules={{ required: "La catégorie de contrat est requise" }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        id={field.name}
                        {...field}
                        options={props.secondaryTables.offerCategoriesData.sort(
                          (a, b) => {
                            return a.label.localeCompare(b.label);
                          }
                        )}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        optionLabel="label"
                        placeholder={t(
                          "offresApresConnexionPage.depotTypeContratPlaceHolder"
                        )}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "box__input")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("offerCategory")}
                </div>
              ) : (
                <Loader />
              )}

              {/* affichage du champ type de contrat selon la valeur du champ catégorie */}
              {typeOfContract &&
              getValues().offerCategory &&
              getValues().offerCategory.label ? (
                getValues().offerCategory.label == "Emploi" ? (
                  <TypeOfContractField
                    placeholder="CDI, CDD, Intérim, Post-doctorant..."
                    disabled={false}
                    options={typeOfContract.filter((item) => {
                      return (
                        item.label == "CDI" ||
                        item.label == "CDD" ||
                        item.label == "INTERIM" ||
                        item.label == "CIFRE" ||
                        item.label == "POST-DOCTORANT"
                      );
                    })}
                  />
                ) : getValues().offerCategory.label == "Job étudiant" ? (
                  <TypeOfContractField
                    placeholder="CDI, CDD, Intérim"
                    disabled={false}
                    options={typeOfContract.filter((item) => {
                      return (
                        item.label == "CDI" ||
                        item.label == "CDD" ||
                        item.label == "INTERIM"
                      );
                    })}
                  />
                ) : getValues().offerCategory.label == "Alternance" ? (
                  <TypeOfContractField
                    placeholder="Contrat d'apprentissage, contrat de professionnalisation"
                    disabled={false}
                    options={typeOfContract.filter((item) => {
                      return (
                        item.label == "CONTRAT D'APPRENTISSAGE" ||
                        item.label == "CONTRAT DE PROFESSIONNALISATION"
                      );
                    })}
                  />
                ) : (
                  <></>
                )
              ) : (
                <TypeOfContractField
                  placeholder="CDI, CDD, Intérim..."
                  disabled={true}
                  options={typeOfContract}
                />
              )}
            </div>
            <div className="depot_columns__trois">
              <h2
                htmlFor="location"
                className={classNames({
                  "p-error": errors.country
                    ? errors.country
                    : errors.department
                    ? errors.department
                    : errors.city
                    ? errors.city
                    : "",
                })}
              >
                {t("offresApresConnexionPage.depotLieu")}
              </h2>
              <div className="questions_location">
                <div className="question">
                  <label
                    htmlFor="country"
                    className={classNames({ "p-error": errors.country })}
                  >
                    {t("offresApresConnexionPage.depotLieuPays")}
                    {getFormErrorMessage("country")}
                  </label>
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: "Le pays est requis" }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setCurrentCountry(e.value);
                          setIsVisibleCity(false);
                          e.value !== "France" && setValue("department", "");
                        }}
                        placeholder={t(
                          "offresApresConnexionPage.depotLieuPaysPlaceHolder"
                        )}
                        options={data}
                        className="box__input"
                      />
                    )}
                  />
                </div>

                <div className="question">
                  {/* <br /> */}
                  <label
                    htmlFor="city"
                    className={classNames({ "p-error": errors.city })}
                  >
                    {t("offresApresConnexionPage.depotLieuVille")}
                    {getFormErrorMessage("city")}
                  </label>
                  <Controller
                    name="city"
                    control={control}
                    rules={{ required: "La ville est requise" }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoFocus
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          if (currentCountry === "France") {
                            if (e.target.value.length > 2) {
                              setIsVisibleCity(true);
                              getCity(e.target.value);
                            } else {
                              setIsVisibleCity(false);
                            }
                          }
                        }}
                        placeholder={t(
                          "offresApresConnexionPage.depotLieuVillePlaceHolder"
                        )}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  {isVisibleCity && (
                    <div className="listOfCities">
                      {listOfCity.map((city, key) => (
                        <div
                          className="searchedCity"
                          key={key}
                          onClick={() => {
                            setValue("city", city[0]);
                            setValue("department", city[1].split(",")[1]);
                            setIsVisibleCity(false);
                          }}
                        >
                          <div className="city">{city[0]}</div>
                          <div className="department">{city[1]}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {currentCountry === "France" && (
                  <div className="question">
                    {/* <br /> */}
                    <label
                      htmlFor="department"
                      className={classNames({ "p-error": errors.department })}
                    >
                      {t("offresApresConnexionPage.depotLieuDepartement")}
                      {getFormErrorMessage("department")}
                    </label>
                    <Controller
                      name="department"
                      control={control}
                      rules={{ required: "Le département est requis" }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          disabled
                          placeholder={t(
                            "offresApresConnexionPage.depotLieuDepartementPlaceHolder"
                          )}
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "box__input")
                          }
                        ></InputText>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="description">
              <h2
                htmlFor="description"
                className={classNames({ "p-error": errors.companyDescription })}
              >
                {t("offresApresConnexionPage.depotDescriptifEntreprise")}
              </h2>
              <Controller
                name="companyDescription"
                control={control}
                render={({ field }) => (
                  <ReactQuill
                    id={field.name}
                    {...field}
                    theme="snow"
                    modules={QuillOptions.modules}
                    formats={QuillOptions.formats}
                    rows={5}
                  />
                )}
              />
            </div>
            <div className="description">
              <h2
                htmlFor="description"
                className={classNames({ "p-error": errors.description })}
              >
                {t("offresApresConnexionPage.depotDescriptifOffre")}
              </h2>
              <Controller
                name="description"
                control={control}
                rules={{ required: "La description de l'offre est requise" }}
                render={({ field }) => (
                  <ReactQuill
                    id={field.name}
                    {...field}
                    theme="snow"
                    modules={QuillOptions.modules}
                    formats={QuillOptions.formats}
                    rows={5}
                  />
                )}
              />
            </div>
            <div className="description">
              <h2
                htmlFor="experience"
                className={classNames({ "p-error": errors.experience })}
              >
                {t("offresApresConnexionPage.depotDescriptifProfil")}
              </h2>
              <Controller
                name="experience"
                control={control}
                render={({ field }) => (
                  <ReactQuill
                    id={field.name}
                    {...field}
                    theme="snow"
                    modules={QuillOptions.modules}
                    formats={QuillOptions.formats}
                    rows={5}
                  />
                )}
              />
            </div>
            <div className="question">
              <div className="q-small">
                <h2
                  htmlFor="levelOfEducations"
                  className={classNames({
                    "p-error": errors.levelOfEducations,
                  })}
                >
                  {t("offresApresConnexionPage.depotNiveau")}
                </h2>
                <Controller
                  name="levelOfEducations"
                  control={control}
                  rules={{ required: "Le niveau d'études est requis" }}
                  render={({ field, fieldState }) => (
                    <MultiSelect
                      appendTo={document.getElementById("app")}
                      id={field.name}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={props.secondaryTables.levelOfEducationsData.filter(
                        (level) => level.label !== "Indifférent"
                      )}
                      optionLabel="label"
                      placeholder={t(
                        "offresApresConnexionPage.depotNiveauPlaceHolder"
                      )}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "box__input")
                      }
                    />
                  )}
                />
                {getFormErrorMessage("levelOfEducations")}
              </div>
            </div>

            <div className="question" key={readOnlySalary}>
              <h2
                htmlFor="salary"
                className={classNames({ "p-error": errors.salary })}
              >
                {t("offresApresConnexionPage.depotTrancheSalaire")}
              </h2>
              {getValues().offerCategory == undefined && (
                <small className="p-error">
                  {t("offresApresConnexionPage.depotTrancheSalaireErreur")}
                </small>
              )}
              <Controller
                name="salary"
                control={control}
                render={() => (
                  <>
                    <div className="depot_rows">
                      <div className="question_salaire">
                        <ToggleButton
                          onLabel={t(
                            "offresApresConnexionPage.depotTrancheSalaireSupprimer"
                          )}
                          offLabel={t(
                            "offresApresConnexionPage.depotTrancheSalairePreciser"
                          )}
                          onIcon=""
                          offIcon=""
                          checked={displaySalary}
                          onChange={(e) => {
                            if (getValues().offerCategory !== undefined) {
                              setValue("salary", [-5, -5]);
                              setDisplaySalary(e.value);
                              setReadOnlySalary(false);
                            }
                          }}
                          aria-label="Confirmation"
                          className="salary-toggle_button"
                        />
                      </div>
                      {displaySalary && (
                        <div className="question_salaire__slider">
                          <SliderSalaire
                            valueSalary={getValues().salary}
                            isEditing={readOnlySalary}
                            categorie={[
                              {
                                label: getValues().offerCategory?.label,
                                id: 2,
                              },
                            ]}
                            onChangeAction={setValue}
                            isSearchBar={false}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              />
            </div>

            <div className="question">
              <div className="q-small">
                <h2
                  htmlFor="publishDuration"
                  className={classNames({ "p-error": errors.publishDuration })}
                >
                  {t("offresApresConnexionPage.depotAffichageOffre")}
                </h2>
                <Controller
                  name="publishDuration"
                  control={control}
                  rules={{ required: "Le délai d'affichage est requis" }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id={field.name}
                      {...field}
                      options={delaiData}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      optionLabel="name"
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "box__input__delay")
                      }
                    />
                  )}
                />
                {getFormErrorMessage("publishDuration")}
              </div>
            </div>
            <div className="question">
              <div className="q-small">
                <ChargementImage
                  image={image}
                  setImage={setImage}
                  imageFile={imageFile}
                  setImageFile={setImageFile}
                  setImageId={setImageId}
                  uploadToast={uploadToast}
                  cancelToast={cancelToast}
                  phototheque={phototheque}
                  setPhototheque={setPhototheque}
                  path="offers"
                  currentImage={props.offerId ? getValues("imageUrl") : null}
                />
              </div>
            </div>
            <div className="question">
              {!verified && (
                <small className="p-error">
                  {t("offresApresConnexionPage.depotChamp")}
                </small>
              )}
            </div>
            <div className="depot_columns">
              <div className="question">
                <h2
                  htmlFor="companyUrl"
                  className={classNames({ "p-error": errors.companyUrl })}
                >
                  {t("offresApresConnexionPage.depotChampSite")}
                </h2>
                <Controller
                  name="companyUrl"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      type="url"
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Entrez une url de type https://myurl.com (ou http)"
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      id={field.name}
                      {...field}
                      placeholder={t(
                        "offresApresConnexionPage.depotChampSitePlaceHolder"
                      )}
                      onChange={(e) => field.onChange(e.target.value)}
                      className={classNames({
                        "p-invalid": errors.companyUrl,
                      })}
                    />
                  )}
                />
              </div>
              <div className="question">
                <h2
                  htmlFor="url"
                  className={classNames({
                    "p-error": errors.urlCandidature || !verified,
                  })}
                >
                  {t("offresApresConnexionPage.depotChampLien")}
                </h2>
                <Controller
                  name="urlCandidature"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      type="url"
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Entrez une url de type https://myurl.com (ou http)"
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      id={field.name}
                      {...field}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder={t(
                        "offresApresConnexionPage.depotChampLienPlaceHolder"
                      )}
                      className={classNames({
                        "p-invalid": errors.urlCandidature,
                      })}
                    />
                  )}
                />
              </div>
            </div>

            <div className="depot_columns">
              {contact.map((item, indexContact) => (
                <React.Fragment key={indexContact}>
                  <div className="question">
                    <h2
                      className={classNames({
                        "p-error": !verified && indexContact == 0,
                      })}
                    >
                      {t("offresApresConnexionPage.depotContact")}
                    </h2>
                    <label
                      className={classNames({
                        "p-error": !verified && indexContact == 0,
                      })}
                    >
                      {t("offresApresConnexionPage.depotContactMail")}
                    </label>
                    <InputText
                      type="email"
                      value={item.email}
                      placeholder="@"
                      onChange={(e) => majContact(e, indexContact, "email")}
                      className="box__input"
                    />
                  </div>
                  <div className="question">
                    <h2 className="h2_salaire">
                      <br />
                    </h2>
                    <label
                      className={classNames({
                        "p-error": !verified && indexContact == 0,
                      })}
                    >
                      {t("offresApresConnexionPage.depotContactTel")}
                    </label>
                    <InputMask
                      mask="+99 (0) 9 99 99 99 99"
                      value={item.phone}
                      placeholder="+33 (0)7 00 00 00 00"
                      onChange={(e) => majContact(e, indexContact, "phone")}
                      className="box__input"
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>

            <div className="question">
              <a onClick={addContact}>
                <i className="pi pi-plus-circle size"></i>{" "}
                {t("offresApresConnexionPage.depotContactAjouter")}
              </a>
            </div>
            <div className="question checkbox">
              <Controller
                name="accessibleForDisabled"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                  />
                )}
              />
              <label htmlFor="accessibleForDisabled" className="spaceLabel">
                <FaWheelchair /> {t("offresApresConnexionPage.depotCheckHandi")}
              </label>
            </div>
            <div className="question checkbox">
              <Controller
                name="remoteWorking"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                  />
                )}
              />
              <label htmlFor="remoteWorking" className="spaceLabel">
                <IoMdDesktop /> {t("offresApresConnexionPage.depotCheckTT")}
              </label>
            </div>
            <div className="question checkbox">
              <Controller
                name="accommodation"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                  />
                )}
              />
              <label htmlFor="accommodation" className="spaceLabel">
                <IoMdHome /> {t("offresApresConnexionPage.depotCheckHome")}
              </label>
            </div>
            <div className="depot_offre_formulaire__boutons">
              {!waitingLoader ? (
                isModifying ? (
                  <BtnBleu
                    type="button"
                    btnTexte={
                      props.auth.isAdmin
                        ? "Modifier l'offre"
                        : currentOffer?.state.statusLabel === "Refusé"
                        ? "Renouveler la demande"
                        : "Modifier l'offre"
                    }
                    btnAction={(e) => {
                      e.preventDefault();
                      if (currentOffer?.state.statusLabel === "Brouillon") {
                        modifyDraft();
                        setWaitingLoader(true);
                      } else onSubmit(getValues());
                    }}
                  />
                ) : props.auth.isAdmin ? (
                  <BtnBleu btnTexte="Déposer l'offre" />
                ) : (
                  <BtnBleu btnTexte="Soumettre l'offre" />
                )
              ) : (
                <button className="waiting_button btn-bleu">
                  <Loader></Loader>
                </button>
              )}
              {visibleModal && (
                <>
                  {props.auth.isAdmin ? (
                    <Modal
                      visible={visibleModal}
                      setVisible={setVisibleModal}
                      header="Votre offre a bien été publiée"
                    >
                      <Margin position="bottom" size="xxs" />
                      <i className="pi pi-send"></i>
                      <div className="description">
                        <p>
                          <center>
                            Votre offre a bien été publiée sur Réseau Alumni.
                          </center>
                        </p>
                      </div>
                      <div className="depot_offre_formulaire__boutons__modal">
                        <BtnBleu
                          btnTexte="Déposer une nouvelle offre"
                          btnAction={() => {
                            clearForm();
                            setVisibleModal(false);
                          }}
                        />
                        <BtnBlanc
                          action="button"
                          btnAction={() => navigateToMyOffers()}
                          btnTexte="Voir toutes mes offres"
                        />
                      </div>
                    </Modal>
                  ) : (
                    <Modal
                      visible={visibleModal}
                      setVisible={setVisibleModal}
                      header="Votre offre a bien été envoyée"
                    >
                      <Margin position="bottom" size="xxs" />
                      <i className="pi pi-send"></i>
                      <div className="description">
                        <p>
                          <center>
                            Votre offre a bien été enregistrée et envoyée à
                            l&apos;équipe Réseau Alumni.
                          </center>
                        </p>
                        <p>
                          <center>Elle sera publiée après validation.</center>
                        </p>
                      </div>
                      <div className="depot_offre_formulaire__boutons__modal">
                        <BtnBleu
                          btnTexte="Soumettre une nouvelle offre"
                          btnAction={() => {
                            clearForm();
                            setVisibleModal(false);
                          }}
                        />
                        <BtnBlanc
                          btnAction={() => navigateToMyOffers()}
                          action="button"
                          btnTexte="Voir toutes mes offres"
                        />
                      </div>
                    </Modal>
                  )}
                </>
              )}
              {!isModifying && (
                <BtnBlanc
                  btnTexte="Prévisualiser l'offre"
                  btnAction={(e) => {
                    e.preventDefault();
                    setVisibleModalBrouillon(!visibleModalBrouillon);
                  }}
                />
              )}

              <button
                className="btn-blanc annuler_button"
                onClick={() => {
                  isModifying
                    ? props.setVisible(false)
                    : (setDisplaySalary(false), clearForm());
                }}
              >
                Annuler
              </button>
              {visibleModalBrouillon && (
                <Modal
                  visible={visibleModalBrouillon}
                  setVisible={setVisibleModalBrouillon}
                  header="Voilà à quoi ressemblera le détail de votre offre une fois publiée"
                >
                  <PrevisualisationOffre
                    offreDetails={getValues()}
                    image={imageFile && !phototheque ? imageFile : imageId}
                    contact={contact[0]}
                    index={0}
                  ></PrevisualisationOffre>
                </Modal>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader></Loader>
      )}
    </form>
  );
};

DepotOffre.propTypes = {
  paramItems: PropTypes.array,
  activeIndex: PropTypes.number,
  auth: PropTypes.object,
  secondaryTables: PropTypes.any,
  offerId: PropTypes.number,
  setVisible: PropTypes.func,
  items: PropTypes.object,
  handleUpdateItems: PropTypes.func,
  handleUpdateOffers: PropTypes.func,
  handleUpdateSecondaryTables: PropTypes.func,
  offers: PropTypes.object,
  recruteur: PropTypes.bool,
  setOffre: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
  items: state.items,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateItems: (item) => dispatch(updateMenu(item)),
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DepotOffre);
